.home {
  width: 75%;
  height: calc(100% - 130px);
  z-index: 10;
  border: transparent 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0;
  overflow: hidden;
}

@media (width <= 1550px) {
  .home {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
    overflow: hidden scroll;
  }
}

@media (width <= 480px) {
  .home {
    width: 100%;
  }
}
