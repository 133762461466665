@keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  0% {
    transform: scale(0%);
  }

  100% {
    transform: scale(100%);
  }
}

.divider-c {
  width: 75%;
  height: 130px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}

.divider {
  width: 100%;
  height: 4px;
  background-color: #2e3464;
  border-radius: 50px;
  margin-top: 10px;
}

.navbar {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
}

.navbar-linksbar {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 80%;
}

.navbar-logo {
  aspect-ratio: 1/1;
  width: 60px;
  height: 60px;
  background-color: #a77acd;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-decoration: none;
}

.navbar-logo-text {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: medium;
  color: #fbeff4;
  font-family: roboto, sans-serif;
  text-decoration: none;
}

.navbar-linksbar-links {
  width: auto;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-link {
  width: auto;
  height: auto;
  margin: 0 10px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar-link:hover {
  opacity: 0.85;
}

.navbar-link-content {
  width: auto;
  height: auto;
  flex-direction: row;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-link-svg {
  width: auto;
  height: 25px;
  margin-right: 4px;
  aspect-ratio: 1/1;
}

.navbar-link-text {
  width: auto;
  height: 100%;
  color: #fbeff4;
  font-family: roboto, sans-serif;
  font-weight: medium;
  font-size: 16px;
  text-align: center;
  line-height: 2.3;
  text-decoration: none;
  margin-top: 3px;
}

.navbar-link-underline {
  background-color: #a77acd;
  width: 100%;
  height: 3px;
  border-radius: 100px;
  visibility: hidden;
  animation: slide 0.3s ease-in-out;
}

.navbar-link-underline:hover {
  opacity: 1;
}

.navbar-socialbar {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-sociallink {
  width: 35px;
  height: 35px;
  outline: #fbeff4 solid 2px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transition: 0.2s ease-in-out;
}

.navbar-sociallink:hover {
  opacity: 0.85;
}

.navbar-sociallink-svg {
  width: auto;
  height: 45%;
  aspect-ratio: 1/1;
}

@media (width <= 1000px) {
  .navbar-socialbar {
    visibility: hidden;
    width: 0%;
  }

  .navbar-linksbar {
    width: 100%;
  }
}

@media (width <= 625px) {
  .navbar-linksbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .navbar-link {
    scale: 75%;
    margin: 0;
  }

  .navbar-logo {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 0;
  }

  .navbar-logo-text {
    font-size: 18px;
  }

  .navbar-linksbar-links {
    width: 100%;
    height: 35px;
    margin: 0;
  }
}