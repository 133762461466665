.projects-header {
  width: 75%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow: visible;
  z-index: 10;
  margin-top: 15px;
  animation: fadein 0.5s;
}

.projects-title {
  width: auto;
  height: auto;
  font-family: roboto, sans-serif;
  color: #fbeff4;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
}

.projects-subtitle {
  width: auto;
  height: auto;
  font-family: roboto, sans-serif;
  font-weight: 500;
  font-size: 36px;
  background-image: linear-gradient(0deg, #6970cd, #d6b3f3);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.projects-divider {
  width: 100%;
  height: 4px;
  background-color: #2e3464;
  border-radius: 50px;
  margin-top: 20px;
  place-self: center center;
  margin-bottom: 30px;
}

@media screen and (width <= 768px) {
  .projects-title {
    font-size: 18px;
  }

  .projects-subtitle {
    font-size: 24px;
  }
}