.about {
  width: 45%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-divider {
  width: 100%;
  height: 4px;
  background-color: #2e3464;
  border-radius: 50px;
}

.about-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0;
  margin: 40px 0 20px;
}

.about-emoji {
  width: 70px;
  height: auto;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.about-text {
  width: calc(100% - 70px);
  height: auto;
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.about-title {
  width: auto;
  height: auto;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: roboto, sans-serif;
  font-weight: medium;
  margin-top: 4px;
  color: #fbeff4;
}

.about-description {
  width: auto;
  height: auto;
  font-size: 20px;
  font-family: roboto, sans-serif;
  font-weight: medium;
  color: #c0c0c0;
}

.about-p-list {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  background-image: linear-gradient(0deg, #6970cd, #d6b3f3);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.about-p-list-item {
  width: auto;
  height: auto;
  font-size: 20px;
  font-family: roboto, sans-serif;
  font-weight: bold;
  color: #e71a1a;
  margin-left: 20px;
}

.about-p {
  width: auto;
  height: auto;
  margin-bottom: 10px;
  font-family: roboto, sans-serif;
  font-weight: medium;
  color: #c0c0c0;
}

.about-p-bold {
  width: auto;
  height: auto;
  font-size: 20px;
  margin-bottom: 10px;
  font-family: roboto, sans-serif;
  font-weight: bold;
  color: #fbeff4;
}

@media (width <= 1550px) {
  .about {
    margin-top: 10px;
    width: 100%;
    height: auto;
    display: flex;
    overflow: visible;
  }
}

@media (width <= 480px) {
  .about {
    margin-bottom: 100px;
  }

  .about-emoji {
    width: 40px;
    height: auto;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    visibility: hidden;
  }

  .about-divider {
    width: 75%;
  }

  .about-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .about-p-list-item {
    font-size: 16px;
  }

  .about-p {
    font-size: 16px;
  }

  .about-p-bold {
    font-size: 16px;
  }
}
