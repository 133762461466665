* {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-x: hidden;
}

body {
  background-color: #1c1f4a;
  animation: fade-in 0.3s ease-in;
  overflow-x: hidden;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.page {
  width: 100vw;
  height: 100vh;
  background-color: #1c1f4a;
  padding: 0;
  overflow-x: hidden;
}

.container {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  visibility: hidden;
}
 
::-webkit-scrollbar-thumb {
  background: #2e3464;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2e3464; 
}

@media (width <= 1499px) {
  .page {
    background-image: linear-gradient(0deg, #1c1f4a, #22265e);
    overflow-x: hidden;
  }
}

@media (width >= 1500px) {
  body::before {
    --size: 0;

    content: "";
    position: absolute;
    left: var(--x);
    top: var(--y);
    width: var(--size);
    height: var(--size);
    background: radial-gradient(circle closest-side, #22265e, transparent);
    transform: translate(-50%, -50%);
    transition: width 0.2s ease, height 0.2s ease;
  }

  body:hover::before {
    --size: 1000px;
  }
}
