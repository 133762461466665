.proj {
  position: relative;
  width: 75%;
  height: 155px;
  border-radius: 10px;
  background-size: 400% 400%;
  overflow: visible;
  border: rgb(255 255 255 / 34.9%) 1px solid;
  box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 75%);
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-decoration: none;
  margin: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}

.proj::after {
  content: "";
  position: absolute;
  inset: 0;
  background-size: 100% 1000px;
  opacity: 0.4;
  pointer-events: none;
  border-radius: 10px;
}

.proj:hover {
  animation-play-state: running;
  transform: translate3d(-5px, -5px, 0);
}

@keyframes gradient-flow {
  0% {
    background-position: 0% 50%;
    opacity: 100;
  }

  50% {
    background-position: 100% 50%;
    opacity: 100;
  }

  100% {
    background-position: 0% 50%;
    opacity: 100;
  }
}

@keyframes fadein {
  from {
    transform: perspective(1500px) rotateX(90deg) scale(0.75);
    opacity: 0;
  }

  to {
    transform: perspective(1500px) rotateX(0deg) scale(1);
    opacity: 1;
  }
}

.proj-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  height: 20%;
  z-index: 10;
  filter: drop-shadow(0 0 5px rgb(0 0 0 / 50%));
  text-decoration: none;
  margin-top: 10px;
}

.proj-time {
  width: auto;
  height: auto;
  color: rgb(255 255 255);
  font-family: roboto, sans-serif;
  font-size: 18px;
  text-decoration: none;
}

.proj-language {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.proj-language-icon {
  width: 25px;
  height: auto;
  text-decoration: none;
}

.proj-language-text {
  width: auto;
  height: auto;
  color: rgb(255 255 255);
  font-family: roboto, sans-serif;
  font-size: 20px;
  margin-left: 5px;
  text-decoration: none;
}

.proj-title {
  width: 98%;
  height: auto;
  color: rgb(255 255 255);
  font-family: roboto, sans-serif;
  font-size: 28px;
  z-index: 10;
  font-weight: bold;
  filter: drop-shadow(0 0 5px rgb(0 0 0 / 50%));
  margin-top: 10px;
  margin-left: 7px;
  text-decoration: none;
}

.proj-desc {
  width: 98%;
  height: auto;
  color: rgb(255 255 255);
  font-family: roboto, sans-serif;
  font-size: 20px;
  z-index: 10;
  font-weight: medium;
  filter: drop-shadow(0 0 5px rgb(0 0 0 / 50%));
  margin-left: 7px;
  text-decoration: none;
  margin-top: 4px;
}

@media screen and (width <= 1400px) {
  .proj {
    width: 75%;
  }

  .proj-title {
    font-size: 24px;
    width: 95%;
  }

  .proj-desc {
    font-size: 16px;
    width: 95%;
  }

  .proj-header {
    width: 95%;
  }

  .proj-time {
    font-size: 18px;
  }

  .proj-language-text {
    font-size: 18px;
  }

  .proj-language-icon {
    width: 24px;
  }
}

@media screen and (width <= 470px) {
  .proj {
    width: 85%;
    height: 130px;
  }

  .proj-title {
    font-size: 16px;
    margin-top: 0;
    margin-left: 4px;
  }

  .proj-desc {
    font-size: 12px;
    margin-top: 2px;
    margin-left: 4px;
  }

  .proj-time {
    font-size: 12px;
  }

  .proj-language-text {
    font-size: 12px;
  }

  .proj-language-icon {
    width: 16px;
  }
}
