.sparkle {
  width: 50px;
  position: absolute;
  scale: 70%;
}

@keyframes init {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


#sparkle-1 {
  transform: scaleY(1.3) translate(-450px, 70px) rotate(10deg);
  animation: sparkle-1 10s infinite ease-in-out;
  opacity: 1;
}

@keyframes sparkle-1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.02;
    transform: scaleY(1.3) translate(-420px, 80px) rotate(30deg);
  }
}

#sparkle-2 {
  transform: scale(70%) scaleY(1.3) translate(-580px, 60px) rotate(10deg);
  animation: sparkle-2 10s infinite ease-in-out;
  filter: invert(100) brightness(200%);
  opacity: 0.02;
}

@keyframes sparkle-2 {
  0% {
    opacity: 0.02;
  }

  50% {
    opacity: 1;
    transform: scale(70%) scaleY(1.3) translate(-620px, 30px) rotate(-10deg);
  }
}

#sparkle-3 {
  transform: scaleY(1.3) translate(450px, -70px) rotate(10deg);
  animation: sparkle-3 10s infinite ease-in-out;
  opacity: 1;
}

@keyframes sparkle-3 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.02;
    transform: scaleY(1.3) translate(420px, -80px) rotate(30deg);
  }
}

#sparkle-4 {
  transform: scale(70%) scaleY(1.3) translate(580px, -60px) rotate(10deg);
  animation: sparkle-4 10s infinite ease-in-out;
  filter: invert(100) brightness(200%);
  opacity: 0.02;
}

@keyframes sparkle-4 {
  0% {
    opacity: 0.02;
  }

  50% {
    opacity: 1;
    transform: scale(70%) scaleY(1.3) translate(620px, -30px) rotate(-10deg);
  }
}

@media (width <= 768px) {
  .sparkle {
    scale: 45%;
  }
}

@media (width <= 480px) {
  .sparkle {
    scale: 35%;
  }
}

@media (width <= 350px) {
  .sparkle {
    scale: 30%;
  }
}
