.spark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: auto;
  scale: 90%;
  overflow: visible;
  margin-top: 15px;
}

.intro {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: visible;
  filter: drop-shadow(0 0 10px rgb(0 0 0 / 26%));
}

.line {
  padding: 0;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
  margin: 5px 0;
  overflow: visible;
}

.l2 {
  margin-left: 150px;
}

.l3 {
  margin-left: 300px;
  align-items: center;
}

.intro-generic {
  width: auto;
  height: auto;
  font-family: roboto, sans-serif;
  font-size: 42px;
  font-weight: medium;
  color: white;
}

.intro-name {
  width: auto;
  height: auto;
  font-family: roboto, sans-serif;
  font-size: 56px;
  font-weight: bold;
  margin-left: 10px;
  background-color: #f3ec78;
  background-image: linear-gradient(0deg, #6970cd, #d6b3f3);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.typing {
  width: 200px;
  height: auto;
  font-family: roboto, sans-serif;
  font-size: 24px;
  font-weight: medium;
  color: white;
  background-image: linear-gradient(-45deg, #474747, rgb(107 107 107));
  border-radius: 15px;
  text-align: center;
  padding: 8px 20px;
  box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 20px;
}

.typing-text {
  width: auto;
  height: auto;
  font-family: roboto, sans-serif;
  font-size: 24px;
  font-weight: medium;
  color: white;
  z-index: 2;
  letter-spacing: 3px;
}

@media screen and (width <= 768px) {
  .l2 {
    margin-left: 80px;
  }

  .l3 {
    margin-left: 160px;
  }

  .intro-generic {
    font-size: 24px;
  }

  .intro-name {
    font-size: 36px;
  }

  .typing {
    width: 150px;
    height: 20px;
    border-radius: 10px;
  }

  .typing-text {
    font-size: 18px;
    letter-spacing: 2px;
  }
}

@media screen and (width <= 480px) {
  .l2 {
    margin-left: 40px;
  }

  .l3 {
    margin-left: 80px;
  }

  .intro-generic {
    font-size: 18px;
  }

  .intro-name {
    font-size: 30px;
  }

  .typing {
    width: 110px;
    height: 10px;
    border-radius: 7px;
  }

  .typing-text {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
